"use client";
import { GoogleAnalytics } from "@next/third-parties/google";

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <>
      {children}
      <GoogleAnalytics gaId="G-2BLNE40TMK" />
    </>
  );
}
