import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.2.0_react-dom@19.0.0_react@19.0.0_sass@1.85.1/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0_react-dom@19.0.0_react@19.0.0_sass@1.85.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/Neue Haas Unica Regular.otf\",\"variable\":\"--font-neue-haas-unica\",\"weight\":\"500\"}],\"variableName\":\"neueHaasSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0_react-dom@19.0.0_react@19.0.0_sass@1.85.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/Neue Haas Unica Medium.otf\",\"variable\":\"--font-neue-haas-medium\",\"weight\":\"600\"}],\"variableName\":\"neueHaasMediumMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0_react-dom@19.0.0_react@19.0.0_sass@1.85.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/NeueHaasUnicaW1G Bold.otf\",\"variable\":\"--font-neue-haas-bold\",\"weight\":\"700\"}],\"variableName\":\"neueHaasBoldMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/Providers.tsx");
